.box {
  display: flex;
  flex-flow: column wrap;
  max-width: 100%;
  padding: 84px 0 24px;
  height: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    max-width: 768px;
    margin: auto;
    gap: 24px;
  }

  @media (min-width: 1024px) {
    max-width: 968px;
  }

  @media (min-width: 1280px) {
    max-width: 1104px;
    flex-wrap: nowrap;
  }

  @media (min-width: 1400px) {
    max-width: 1280px;
    gap: 48px;
  }

  @media (min-width: 1600px) {
    max-width: 1440px;
  }
  @media (min-width: 1800px) {
    max-width: 100%;
    padding: 64px;
    gap: 64px;
  }
}

.box .left-side {
  display: flex;
  flex-grow: 0;
}

.box .frame-1 {
  height: 100%;
  background-color: #171717;
  border: 3px solid;
  border-color: #2b2b2b;
  border-radius: 20px;
  padding: 16px;
  margin: 16px 24px 84px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 48px 48px;
    margin: 0;
  }
}

.box .image {
  position: relative;
  max-width: 60%;
  top: -64px;
  margin: -32px auto -84px;

  @media (min-width: 768px) {
    max-width: 40%;
    right: 0;
    top: 0;
    margin: -32px auto -84px;
  }

  @media (min-width: 1280px) {
    max-width: 100%;
  }

  @media (min-width: 1400px) {
    margin: -64px 0 -132px;
  }
}

.box .right-side {
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.box .frame-2 {
  background-color: #171717;
  border: 3px solid;
  border-color: #2b2b2b;
  border-radius: 20px;
  padding: 16px;
  margin: 16px 24px 112px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    padding: 48px;
    margin: 0 0 24px;
  }

  @media (min-width: 1400px) {
    margin: 0 0 48px;
  }
}

.box .image-2 {
  position: relative;
  max-width: 60%;
  top: -32px;
  margin: -64px auto 0;

  @media (min-width: 768px) {
    align-self: flex-end;
    top: 0;
    margin: 0;
    max-width: 180px;
    max-height: 180px;
  }

  @media (min-width: 1400px) {
    max-width: 200px;
    max-height: 200px;
    margin-left: 16px;
  }
}

.box .frame-3 {
  background-color: #171717;
  border: 3px solid;
  border-color: #2b2b2b;
  border-radius: 20px;
  padding: 16px;
  z-index: 5;
  margin: 16px 24px 84px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    padding: 48px 48px 48px 16px;
    margin: 0;
  }
}

.box .header {
  color: #df832f;
  font-family: Montserrat, Helvetica;
  font-size: 30px;
  font-weight: 500;
  line-height: 32px;
  white-space: nowrap;
  padding: 0 8px;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 38px;
  }
}

.box .subheader {
  color: #fff;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 48px;
  white-space: nowrap;
  padding: 0 8px;
}

.box .paragraph {
  align-items: flex-start;
  display: flex;
  padding: 16px 8px;
}

.box .p {
  color: #cfcfcf;
  font-family: Montserrat, Helvetica;
  font-weight: 400;
  line-height: 24px;
  width: fit-content;
  font-size: 16px;
}

.box .link-wrapper {
  align-items: center;
  display: flex;
  gap: 16px;
  padding: 8px;
}

.box .text-wrapper {
  color: #df832f;
  font-family: Montserrat, Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
  width: fit-content;
}

.box .spear-svg {
  max-width: 38px;
  position: relative;
}

.box .image-3 {
  position: relative;
  width: 300px;
}

.rotative-img img {
  position: absolute;
  margin: 0 auto;
  max-width: 250px;

  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 200px;
  }

  @media (min-width: 1400px) {
    max-width: 250px;
  }
}

.rotative-img {
  position: relative;
  top: -112px;
  margin: 0 auto 164px;
  max-width: 250px;
  min-width: 250px;
  height: 100%;

  @media (min-width: 768px) {
    justify-content: flex-end;
    align-items: flex-end;
    top: 0;
    max-width: 200px;
    min-width: 200px;
    margin: 0;
  }

  @media (min-width: 1400px) {
    max-width: 250px;
    min-width: 250px;
    margin-right: 16px;
  }
}

.rotate1 {
  animation: rotate-clockwise 25s linear infinite;
}

.rotate2 {
  animation: rotate-counter-clockwise 29s linear infinite;
}
@keyframes rotate-clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-counter-clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
