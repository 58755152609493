.footer-wrap {
  display: block;
  position: relative;
  margin-top: 96px;
  padding-bottom: 9px;
  width: 100%;

  .upper-footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 24px;
    border-top: 0.5px solid;
    border-color: rgba(85, 85, 85, 25%);

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      padding: 36px 92px;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      @media (min-width: 768px) {
        width: 40%;
      }
      @media (min-width: 1400px) {
        width: 30%;
      }
    }

    .links {
      display: flex;
      gap: 92px;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      margin-top: 48px;

      @media (min-width: 768px) {
        margin-top: 0;
        width: 40%;
        align-items: flex-start;
        justify-content: flex-end;
      }

      .column {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 768px) {
          align-items: flex-end;
        }
      }
    }

    .heading {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      padding-bottom: 16px;

      @media (min-width: 768px) {
        font-size: 20px;
      }
    }

    p {
      color: #d1d1d1;
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    .link {
      color: #faf0f0;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      padding: 6px 0;
      cursor: pointer;
      transition: color 300ms ease-in-out;

      &:hover {
        color: #df832f;
      }

      @media (min-width: 768px) {
        font-size: 18px;
        text-align: right;
      }
    }
  }

  .lower-footer-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 16px;
    padding: 8px 0;
    border-top: 1px solid grey;

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin: 0 92px;
    }

    p {
      font: normal normal normal 14px/42px Montserrat;
      color: #fff;
      opacity: 0.88;
      @media (min-width: 768px) {
        font: normal normal normal 18px/42px Montserrat;
      }
    }

    .links {
      a {
        margin-right: 14px;

        &:last-child {
          margin-right: 0;
        }

        img {
          max-width: 36px;
        }
      }
    }
  }
}
