.orangeGlow {
  position: absolute;
  pointer-events: none;
  top: 15%;
  left: 15%;
  width: 256px;
  height: 256px;
  background-color: #df832f;
  filter: blur(512px);
  opacity: 0.3;
  z-index: -1;
  transform: translate3d(0, 0, 0); /* fix for safari glitch */
  border-radius: 50%;
  @media (min-width: 960px) {
    width: 480px;
    height: 480px;
  }
}
.whiteGlow {
  position: absolute;
  pointer-events: none;
  bottom: 5%;
  right: 5%;
  width: 256px;
  height: 256px;
  background-color: #faf0f0;
  filter: blur(1024px);
  opacity: 0.3;
  z-index: -1;
  transform: translate3d(0, 0, 0); /* fix for safari glitch */
  border-radius: 50%;
  @media (min-width: 960px) {
    width: 480px;
    height: 480px;
  }
}
