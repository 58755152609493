.wrapper-otoken {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 12px;

  @media (min-width: 960px) {
    padding: 24px 0;
    width: 600px;
  }

  .block {
    display: flex;
    flex-direction: column;
    border: 2px solid gray;
    padding: 16px;
    border-radius: 8px;
    gap: 8px;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #c1c1c1;
    }

    .value {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: right;
      color: white;
    }
  }
}
