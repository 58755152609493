.table-row-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
  color: #dedbf2;
  padding: 16px;
  background-color: rgba(255, 255, 255, 10%);
  margin-bottom: 1px;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    align-items: center;
    padding: 20px 24px;
  }
}

.sticky-table-header {
  display: none;
  justify-content: space-between;
  padding: 0.475rem 24px;
  z-index: 100;

  @media (min-width: 1024px) {
    display: flex;
    margin-bottom: -19px !important;
    top: -19px !important;
  }
  @media (min-width: 1280px) {
    margin-bottom: 0 !important;
    top: 0 !important;
  }
}
