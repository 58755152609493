.wrapper {
  max-width: 1104px;
  margin: 16px auto;
  width: 100%;
}

.header {
  width: 100%;
  margin: 48px 0 24px;
}

.header-text {
  color: #ffffffde;
  font-size: 34px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 28px;
  }
}

.steps-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.step {
  display: flex;
  flex-direction: row;
  background: #ffffff1a;
  padding: 24px;
  border-radius: 10px;
}

.step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 80px;
  min-width: 80px;
  height: 80px;
  background: linear-gradient(90deg, #df832f 0%, #e3974f 33.33%, #e6a160 66.67%, #df832f 100%);
  color: white;
  font-size: 34px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 22px;
    width: 50px;
    min-width: 50px;
    height: 50px;
  }
}

.step-title {
  color: white;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.18px;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }
}

.step-text {
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.step-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 25%;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: center;
    margin-top: 16px;
  }
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 16px;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1.45px;
  background: linear-gradient(90deg, #df832f 0%, #e3974f 33.33%, #e6a160 66.67%, #df832f 100%);

  @media (max-width: 600px) {
    height: 42px;
    padding: 8px;
    font-size: 14px;
    width: 100%;
  }
}
